import AccordionItem from "./AccordionItem";
import StandardImageList from "./StandardImageList";
import { 
  widowContentGroup,
  widowInstallGroup,
  leesGroup,
  bananaGroup,
  cyclistPlusKatieGroup,
  mtsGroup,
  fordeGroup
} from "../data/imageData";

export default function WorkImageCollection() {
  return (
    <>
      <AccordionItem title="Archive 1">
        <StandardImageList imageData={leesGroup} />
      </AccordionItem>
      <AccordionItem title="Archive 2">
        <StandardImageList imageData={widowInstallGroup} />
      </AccordionItem>
      <AccordionItem title="Archive 3">
        <StandardImageList imageData={widowContentGroup} />
      </AccordionItem>
      <AccordionItem title="Archive 4">
        <StandardImageList imageData={bananaGroup} />
      </AccordionItem>
      <AccordionItem title="Archive 5">
        <StandardImageList imageData={cyclistPlusKatieGroup} />
      </AccordionItem>
      <AccordionItem title="Archive 6">
        <StandardImageList imageData={mtsGroup} />
      </AccordionItem>
      <AccordionItem title="Archive 7">
        <StandardImageList imageData={fordeGroup} />
      </AccordionItem>
    </>
  )
}