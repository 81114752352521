export const altTexts: Record<string, string> = {
  'banana_install.jpg': 'An installation view of an exhibition showing a painting patterned with a red icon like a reversed question mark, a wallpaper rubbed away to reveal a similar pattern, a pile of coffeee grinds in the corner and an elaborate bouquet laid on its side on the floor',
  'banana.jpg': 'A close-up image of a banana pinned horizontally to a wall with the bend pointed down to form a frown, with two slices of banana pinned above it to form eyes',
  'claycups.jpg': 'A large group of rough-hewn and unfired pinch pots of various sizes made from a gray clay',
  'tea.jpg': 'A white porcelain cup and saucer set high into a recess in a gallery wall',
  'pilot_install.jpg': 'And installation view of an exhibition featuring multiple works and objects in a corner: a large polka-dot painting on the wall, a set of old, white, neon letters (a D and a B), neither connected nor on, a framed work with a blue screen print of a face on the glazing, and a polka-dot scarf hanging on the wall', 
  'plants-tarp.jpg': 'A group of 10 young castor bean plants of various heights in brown plastic pots arranged on a green tarp',
  'plants_corner.jpg': 'A corner of a gallery with a group of very young deadly nightshade plants barely big enough to be seen over the rim of black plastic nursery pots . A wallpaper with a very faint pattern lines the walls from the floor to about 3 feet up the wall',
  'plants_corner_detail.jpg': 'A group of very young deadly nightshade plants barely big enough to be seen over the rim of black plastic nursery pots in a corner', 
  'widow_install1.jpg': 'An installation view of an exhibition showing many elements: in the center a very large pile of soil, 7 to 8 feet in diameter and 3 to 4 feet tall at the center. In front of the soil are two pedestals, each with one side of visible plywood and one side of yellow formica. On one pedestal there is a single champagne flute filled with dark reddish brown lees. On the other are two flutes with champagne. In front of the pedestals are a number of small, poisonous plants in black plastic nursery pots. In the background are two large patterned paintings with icons of champagne bubbles in a faded yellow and a small nursery tray of even smaller plants.',
  'lees1.jpg': 'A straight on image of a plywood pedestal about 10 inches by 10 inches by 45 inches. On the pedestal: a small pile of soil and two champagne flutes with thick, viscous fluids that are the byproduct of champagne production. One is dark red and one is light brown. In the background is a small patterned painting.',
  'lees2.jpg': 'A close-up image of a plywood pedestal about 10 inches by 10 inches by 45 inches. On the pedestal: a small pile of soil and two champagne flutes with thick, viscous fluids that are the byproduct of champagne production. One is dark red and one is light brown. In the background is a small patterned painting.',
  'lees3.jpg': 'An image shot from a 45 degree angle of a plywood pedestal about 10 inches by 10 inches by 45 inches. On the pedestal: a small pile of soil and two champagne flutes with thick, viscous fluids that are the byproduct of champagne production. One is dark red and one is light brown. In the background is a small patterned painting.',
  'scratchoff_orangeblob.jpg': 'A framed work of an image covered in bright orange scratch off ink that has been scratched away in the middle in a roughly circular pattern revealing a black and white repeating pattern of champagne bubbles',
  'madame_metal.jpg': 'A layered work on a wall: a plywood base, with a pasted portrait of the widow Madame Clicquot. Over the portrait is a tight grid or lattice of polished stainless steel bar stock',
  'widow_painting.jpg': 'A painting whose ground consists of a loosely painted square of gesso at the center and raw linen beyond that. Overlaid is a tight, repeating pattern of a group of bubbles',
  'pilot_install2': 'An exhibition view of multiple elements: in the background are 4 polka-dot pattern paintings of decreaing sizes. The largest and left-most and the smallest and right-most are on the wall. The middle two sizes are on the floor leaned against the wall. In the foreground is a strip of astro turf bent up slightly in the middle. On it are two small, chrome-plated scones and a plant.',
  'poison_plant1.jpg': 'A poisonous plant in a green nursery pot set on a low platform made of a light green formica laminated plywood with metal casters',
  'poison_plant2.jpg': 'A poisonous plant in a green nursery pot set on a low platform made of a light green formica laminated plywood with metal casters',
  'poison_plant3.jpg': 'A poisonous plant in a green nursery pot set on a low platform made of a light green formica laminated plywood with metal casters',
  'cyclist_painting2.jpg': 'A painting with a washy white ground and a square-constrained, tight pattern of small red squiggles like a reversed question mark',
  'cyclist_katie_combo.jpg': 'A painting constisting of layered patterns of icons including a red squiggle like a reversed question mark, a black line drawing of a rabbit, and an egg filled in like a maze',
  'katie_painting1.jpg': 'A painting with a washy white ground and a square-constrained, pattern consisting of a black line drawing of a rabbit and an egg filled in like a maze',
  'forde1.jpg': 'A n installation view of an exhibition. In the foreground is a small round table and a wooden school chair. On the table is a computer monitor and a keyboard. On the screen is a text-based adventure game. It is impossible to read from the image but shows white text on a black background. In the background is a black curtain to the left and a framed work with a yellow, inky print of a woman\'s face, printed diretly on the glazing.',
  'forde2.jpg': 'A dim room with a glossy black pedestal with no top. Above it is a hanging, illuminated lighbulb. Behind it on the floor is a pack of 6 1-liter water bottles of the brand Valser.',
  'forde3.jpg': 'A n installation view of an exhibition. In the foreground is a small round table and a wooden school chair. On the table is a computer monitor and a keyboard. On the screen is a text-based adventure game. It is impossible to read from the image but shows white text on a black background. In the background is a framed work with a dark blue, inky print of a mans face looking to the right printed diretly on the glazing.'
}