import { IImage } from "../interfaces/IImage";
import { altTexts } from "./altTexts";

const prefix = '../../images';

const allHorizontals = [
  'banana_install.jpg',
  'banana.jpg',
  'claycups.jpg',
  'tea.jpg',
  'pilot_install.jpg', 
  'plants-tarp.jpg',
  'plants_corner.jpg',
  'plants_corner_detail.jpg', 
  'widow_install1.jpg',
];

const allVerticals = [
  'forde_install1.jpg',
  'lees1.jpg',
  'lees2.jpg',
  'lees3.jpg',
  'scratchoff_orangeblob.jpg',
  'madame_metal.jpg',
  'widow_painting.jpg',
  'pilot_install2',
  'poison_plant1.jpg',
  'poison_plant2.jpg',
  'poison_plant3.jpg',
  'cyclist_painting2.jpg',
  'cyclist_katie_combo.jpg',
  'katie_painting1.jpg',
  'forde1.jpg',
  'forde2.jpg',
  'forde3.jpg',
];


const makeImageFormatter = (orientation:string) => (imgPath: string): IImage => ({
  img: `${prefix}/${orientation}/${imgPath}`,
  alt: altTexts[imgPath]
});

const formatHorizontals = makeImageFormatter('horizontals');
const formatVerticals = makeImageFormatter('verticals');

export const imageData = allHorizontals.map(formatHorizontals);

export const leesGroup = allVerticals.slice(1,4).map(formatVerticals); 
export const widowInstallGroup = allHorizontals.slice(6).map(formatHorizontals);
export const widowContentGroup = allVerticals.slice(4, 7).map(formatVerticals);
export const plantGroup = allVerticals.slice(8, 11).map(formatVerticals);
export const bananaGroup = allHorizontals.slice(0, 3).map(formatHorizontals);
export const mtsGroup = allHorizontals.slice(3, 6).map(formatHorizontals);
export const cyclistPlusKatieGroup = allVerticals.slice(11, 14).map(formatVerticals);
export const fordeGroup = allVerticals.slice(14).map(formatVerticals);
