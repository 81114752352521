import { Link } from '@mui/material/';

import AccordionItem from './AccordionItem';
import WorkImageCollection from './WorkImageCollection';

export default function Nav() {
  return (
    <div>
      <AccordionItem title="Works">
        <WorkImageCollection />
      </AccordionItem>
      <AccordionItem title="Other Projects">
        <AccordionItem title="Interactive Fiction" body="Click to play (scroll up for essay):">
          <Link target="_blank" rel="noopener" href="https://www.canopycanopycanopy.com/contents/you-cant-see-any-such-thing" underline="hover">
            "You Can't See Any Such Thing (Triple Canopy commission)"
          </Link>
        </AccordionItem>
      </AccordionItem>
      {/* <AccordionItem title="Web3" body="Coming Soon" /> */}
      <AccordionItem title="About" body="Lives and works in Los Angeles." /> 
    </div>
  );
}