import { ImageListItem, Box, imageListItemClasses } from '@mui/material/';
import { IImage } from '../interfaces/IImage';

interface IStandardImageList {
  imageData: IImage[];
}


export default function StandardImageList(props: IStandardImageList) {
  return (
    <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xxs: "repeat(1, 1fr)",
            xl: "repeat(3, 1fr)"
          },
          [`& .${imageListItemClasses.root}`]: {
            display: "flex",
            flexDirection: "column",
          }
        }}
      >
      {props.imageData.map((item: IImage) => (
        <ImageListItem key={item.img}>
          <img
            src={item.img}
            srcSet={item.img}
            alt={item.alt}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </Box>
  );
}