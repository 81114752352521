import { useEffect } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import Nav from './components/Nav';

const TRACKING_ID = 'UA-2384818-1'

function App() {
  const setGA = () => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview('home view');
  }  

  useEffect(() => {
    setGA();
  }, []);

  return (
    <>
      <Nav />
    </>
  );
}

export default App;
