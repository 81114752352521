import { ReactNode } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IAccordionItemProps {
    title: string,
    body?: string,
    children?: ReactNode
}

export default function AccordionItem(props: IAccordionItemProps) {
    return (
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant='button'>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {props.body && <Typography>{props.body}</Typography>}
            {props.children}
        </AccordionDetails>
      </Accordion>
    )
}